import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService, Ub3httpService} from '@common/services';
import {accountReactivateModule} from '../../../../../../../../frontend/accountReactivateModule';
import {accountRequestModule} from '../../../../../../../../frontend/accountRequestModule';
import {CookieService} from 'ngx-cookie-service';

var t: any = null;
var scope = null;
var clog = null;
var http = null;

@Component({
  selector: 'data-survey',
  templateUrl: './data-survey.component.html',
  styleUrls: ['./data-survey.component.scss']
})
export class DataSurveyComponent implements OnInit {
  state: any = null;
  userName: string = null;
  flags = {};
  publicPage(b) { };
  form = null;
  lists = {};
  user = {role: "public", signedIn: false, firstName: "Public", lastName: "User"};
  showSelectProjects = false;
  doneWithLimitedLogin = false;
  enableDataSurvey = false;
  isReactivateSurvey = true;
  fiscalYear = null;

  constructor(
    private commonService: CommonService,
    private h: Ub3httpService,
    public cookies: CookieService,
    private modalService: NgbModal
  ){
        // this component is special, its a franky mixture of various other components
        // scope.flags will point to the flags storage defined in account-edit component
        //
        t = scope = this;
        clog = t.commonService.ub3consoleLog;
        http = h;
        //t.enableDataSurvey = (window.location.hostname === 'localhost');  //use this line when turn OFF in production
        t.enableDataSurvey = false;  //use this line when turn ON in production
        t.state = t.commonService.getSharedObject('app','state');
        t.form  = t.commonService.getSharedObject('account-edit', 'form');
        t.flags = t.commonService.getSharedObject('account-edit', 'flags');
        t.lists = t.commonService.getSharedObject('account-edit', 'lists');
        t.form.good = true;
  }

  ngOnInit(): void {

    accountReactivateModule.init(http, http, t.cookies, scope, 'Reactivate an Account', false, Promise, setTimeout, true);
    getYear(http, function(y){ t.fiscalYear = y; });
    accountRequestModule.overWriteScope(scope);

    document.body.classList.add('public-page');
    t.commonService.doWhenAPICallsDone(function(){
      t.userName = t.state.user.signedIn ? t.state.user.userName : null;
      t.flags.isReactivateSurvey = t.isReactivateSurvey;
    });

  }


  cancelAccountReactivation = function(){
    localStorage.removeItem('limLoginReason');
    t.commonService.signOut();
  }

  afterLimitedLogin(){
    localStorage.setItem('limLoginReason', 'dataSurvey');
    t.doneWithLimitedLogin = true;
    //accountReactivateModule.getAccountInfo(http, http, t.form, t.flags, t.lists, Promise,  null, scope);
    //t.commonService.getLimitedProjectsList(t.lists);

    t.commonService.setUser(t.state, function(){
        //t.currentUser = t.state.user;
        //t.menuItems = t.commonService.convertMenu(t.state.menuItems, t.state.user);
    });

    accountReactivateModule.getSurveyData(scope, Promise);
  }

  reactValidate(){
  }
  goHome(){
    // window.location.assign("/");
    localStorage.removeItem('limLoginReason');
    t.commonService.signOut(null, scope, false);
  };

}

function getYear(http, cb){
    http.get('/public/domainGlobals').subscribe(function(resp){
        if (resp.success){
            cb(resp.domainGlobals.dataSurveyFiscalY);
        }else{
            // error message here
            cb(null);
        }
    });
}
